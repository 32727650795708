import { TrackingService } from '../trackingService';

const articlePageConversionAfterSearch = (data: Record<string, string>[], userToken?: string) => {
  const [clickedObjectIDsAfterSearch] = TrackingService.getEventsBetweenTwoLatestPageViews(
    data || [],
    (eventsBetween) => eventsBetween.find((item) => item?.event === 'clickedObjectIDsAfterSearch'),
  );

  if (clickedObjectIDsAfterSearch) {
    const lastClickedObject = clickedObjectIDsAfterSearch?.eventValues as unknown as {
      indexName: string;
      objectIDs: string[];
      queryID: string;
    };

    if (lastClickedObject) {
      TrackingService.convertedObjectIDsAfterSearch({
        indexName: lastClickedObject?.indexName,
        objectIDs: lastClickedObject?.objectIDs as unknown as string[],
        queryID: lastClickedObject?.queryID,
        userToken: userToken ?? '',
        eventName: 'article_converted_after_recipe_click',
      });
    }
  }
};

export const InspirationTracking = {
  articlePageConversionAfterSearch,
};
