import { RecipeRating, RecipeResult } from 'types/Recipes';
import { sendGTMEvent } from '@next/third-parties/google';
import { TrackingService } from '../trackingService';

/**
 * Main Recipe Pages tracker
 * @param difficulty Difficulty definitions in current language from GraphCMS
 * @param tags Array of recipe tags
 * @param recipe Recipe object
 * @param recipeRating Current recipe Ratings
 */
const recipePage = (
  difficulty: string,
  tags: any[],
  recipe?: RecipeResult,
  recipeRating?: RecipeRating,
): void => {
  const utensil = recipe
    ? recipe?.IngredientsAndEquipmentBlocks.reduce(
        (x: any, y: any) => x.concat(y.VisibleEquipmentItemPositions),
        [],
      ).map((z: any) => z).length
    : 0;

  const ingredient = recipe
    ? recipe?.IngredientsAndEquipmentBlocks.reduce(
        (x: any, y: any) => x.concat(y.Ingredients),
        [],
      ).map((z: any) => z).length
    : 0;

  sendGTMEvent({
    customDimensionValues: {
      recipe_id: recipe?.Id || null, // eg. "3545477"
      recipe_title: recipe?.Title || null, // eg. "Chocolate Victoria Sponge cake"
      recipe_category: recipe?.Category?.GlobalCategory?.Title || null, // eg. "Chocolate cakes"
      recipe_subcategory: recipe?.Category?.Title || null, // eg. "Chocolate Sponge cakes"
      recipe_tags: tags || null, // eg. "cake, gluten free, chocolate"
      recipe_difficulty: difficulty || null,
      // eg. "easy' or "intermediate" or...
      recipe_cost: recipe?.Price || null, // eg. "expensive" or "inexpensive"
      recipe_rating: recipeRating?.value || null,
      recipe_rating_number: recipeRating?.amount || null, // eg. 4.2 (number format)
      // amount of rating, eg. 37 (number format)
      recipe_serving_amount:
        recipe?.ToServings ||
        parseInt(recipe?.ServingsSummaryText?.match(/\d+/)?.[0] || '', 10) ||
        null, //  eg. 12 (number format)
      // TODO: update total time to use recipe data, once it will be available
      recipe_duration_total: recipe?.TotalTimeInMinutes || 0,
      // eg. 90 (minutes in number format)
      recipe_duration_preparation: recipe?.PreparationTimeInMinutes || 0,
      // eg. 20 (minutes in number format)
      recipe_duration_baking: recipe?.BakingTimeInMinutes || 0,
      // eg. 40 (minutes in number format)
      recipe_duration_resting: recipe?.RestingTimeInMinutes || 0,
      // eg. 30 (minutes in number format) */
      recipe_ingredients_number: ingredient || 0,
      // eg. 7 (amount of ingredients in the list  in number format)
      recipe_utensils_number: utensil || 0,
      // eg. 3 (amount of utensil in the list in number format)
    },
  });
};

/**
 * Recipe steps tracker
 * @param recipeTitle name of current recipe
 * @param step current step number
 */
const recipeStep = (recipeTitle: string, step: number): void => {
  if (typeof window === 'undefined') return;
  sendGTMEvent({
    event: 'view_preparation_steps_recipe',
    eventValues: {
      ec: 'teaser',
      ea: 'view',
      el: `${recipeTitle}_${step + 1}`,
      preparation_step: `${step + 1}`,
    },
  });
};

/**
 * Recipe preparations tracker
 * @param recipeTitle name of current recipe
 */
const recipePreparation = (recipeTitle: string): void => {
  if (typeof window === 'undefined') return;
  sendGTMEvent({
    event: 'view_preparation_recipe',
    eventValues: {
      ec: 'teaser',
      ea: 'view',
      el: `${recipeTitle}`, // eg. "chocolate cake"
    },
  });
};

/**
 * Used to track which products are clicked on in the ingredients and equipment component
 * @param data product data
 */
const clickProductLinkRecipe = (data: any): void => {
  if (typeof window === 'undefined') return;

  sendGTMEvent({
    event: 'click_product_link_recipe',
    eventValues: {
      ec: 'recipe',
      ea: 'click product link',
      el: data.summary || '', // eg. "Dr. Oetker Madagascan vanilla extract"
      target_product_name: data.summary || '', // eg. "Dr. Oetker Madagascan vanilla extract"
      target_product_id: data.gtinId || '', // eg. "AE5654X"
    },
  });
};

/**
 * When a subcategory card is clicked on the product landing page
 * in the CategorySubcategoryWrapper component
 * @param card Product
 */
const clickSeeSubcategoryRecipe = (card: any): void => {
  sendGTMEvent({
    event: 'click_see_subcategory_recipe',
    eventValues: {
      ec: 'recipe landing page',
      ea: 'click',
      el: `${card?.subcategory || 'na'}`,
      recipe_category: card?.category || 'category', // eg. "Pizza"
      recipe_subcategory: card?.subcategory || 'na', // eg. "Ristorante Pizza"
    },
  });
};

/**
 * When a subcategory card is clicked on the recipe landing page
 * in the CategorySubcategoryWrapper component
 * @param card
 */
const clickSeeCategoryRecipe = (card: any): void => {
  if (typeof window === 'undefined') return;
  sendGTMEvent({
    event: 'click_see_category_recipe',
    eventValues: {
      ec: 'recipe landing page',
      ea: 'click',
      el: `${card?.category || 'na'}`,
      recipe_category: card?.category || 'na', // eg. "Pizza"
    },
  });
};

/**
 * Recipe card clicked tracker on category landing page
 * @param targetCategory target category the card has available in it's data object
 * @param targetSubcategory target subcategory the card has available in it's data object
 * @param targetTitle target title the card has available in it's data object
 * @param id recipe id
 * @param recipeDifficulty difficulty of recipe
 * @param recipeRating rating of recipe
 * @param recipeDurationTotal duration of recipe
 * @param filterCategory current filter category
 * @param filters applied filters values
 * @param sorting current sorting mechanism
 */
const seeRecipeClick = (
  targetCategory: string,
  targetSubcategory: string,
  targetTitle: string,
  id: number,
  recipeDifficulty: string,
  recipeRating: string,
  recipeDurationTotal: string,
  filterCategory: string,
  filters: string,
  sorting: string,
): void => {
  sendGTMEvent({
    event: 'click_see_recipe',
    eventValues: {
      ec: 'recipe landing page',
      ea: 'click',
      el: targetTitle,
      target_recipe_id: id, // recipe id
      target_recipe_title: targetTitle, // title on the card
      target_recipe_category: targetCategory, // category of the card coming from it's data object
      target_recipe_subcategory: targetSubcategory,
      // ^ subCategory of card coming from it's data object
      target_recipe_difficulty: recipeDifficulty, // eg. "easy"
      target_recipe_rating: recipeRating, // eg. 4.2 (number format)
      target_recipe_duration_total: recipeDurationTotal, // eg. 90 (minutes in number format)
      filter_category: filterCategory, // filter category currently in use
      filter_used: filters, // filter within that category in use
      sorting_used: sorting, // the sorting option in use
    },
  });
};

/**
 * When the user clicks the rate button on a recipe in the rating component
 * @param value is the given rating
 * @param title is the title of the item that is being rated
 */
const clickRatingRecipe = (value: number, title: string): void => {
  sendGTMEvent({
    event: 'click_rating_recipe',
    eventValues: {
      ec: 'recipe',
      ea: 'click',
      el: value,
      recipe: title,
      rating: value,
    },
  });
};

/**
 * When the user clicks the print recipte button on a recipe in the recipe overview component
 * @param title is the recipe name
 */
const clickPrintRecipe = (title: string): void => {
  sendGTMEvent({
    event: 'click_print_recipe',
    eventValues: {
      ec: 'recipe',
      ea: 'print',
      el: title,
    },
  });
  window.print();
};

// ---- RECIPE CARDS ----
/**
 * Recipe card clicked tracker in Subcategory component on category landing page
 * @param targetCategory target category the card has available in it's data object
 * @param targetSubcategory target subcategory the card has available in it's data object
 */
const subcategoryComponentRecipeClick = (
  targetCategory: string,
  targetSubcategory: string,
): void => {
  sendGTMEvent({
    event: 'click_see_subcategory_recipe',
    eventValues: {
      ec: 'recipe landing page',
      ea: 'click',
      el: `${targetSubcategory}`,
      target_category: targetCategory, // category of the card coming from it's data object
      target_subcategory: targetSubcategory, // subCategory of card coming from it's data object
    },
  });
};

/*
 * When the user clicks on one of the recipes in the teasers Grid module
 */
const clickTeaserSeeRecipe = (data: any) => {
  sendGTMEvent({
    event: 'click_teaser_see_recipe',
    eventValues: {
      ec: 'teaser recipe',
      ea: 'click',
      el: `${data?.containerType}_${data?.teaserName || 'teaserName'}`, // eg. "grid_Chocolate cake"
      container_title: data?.containerTitle || 'containerTitle', // eg. "more delicious ideas for you"
      container_position: data?.containerPosition || 'containerPosition',
      // ^ if there is different containers/modules in the page,
      // which position does this one have. eg. "2"
      container_type: data?.containerType || 'containerType',
      teaser_name: data?.teaserName || 'teaserName', // eg. "Chocolate cake"
      teaser_position: data?.teaserPosition || 'teaserPosition',
      // ^ position of the teaser inside the container/module eg. "3"
      teaser_recipe_id: data?.teaserRecipeId || 'teaserRecipeId', // eg. "3545477"
      teaser_recipe_title: data?.teaserRecipeTitle || 'teaserRecipeTitle', // eg. "Chocolate Victoria Sponge cake"
      teaser_recipe_difficulty: data?.teaserRecipeDifficulty || 'teaserRecipeDifficulty', // eg. "easy' or "intermediate" or...
      teaser_recipe_rating: data?.teaserRecipeRating || 'teaserRecipeRating', // eg. 4.2 (number format)
      teaser_recipe_duration_total: data?.teaserRecipeDuration || 'teaserRecipeDuration', // eg. 90 (minutes in number format)
    },
  });
};

/*
 * When the user clicks on the CTA on the cards carousel/dynamic cards grid
 */
const clickTeaserRecipeList = (data: any) => {
  sendGTMEvent({
    event: 'click_teaser_recipe_list',
    eventValues: {
      ec: 'teaser',
      ea: 'click',
      el: 'carousel_list',
      container_title: data?.containerTitle || 'containerTitle', // eg. "more delicious ideas for you"
      container_position: data?.containerPosition || 'containerPosition',
      container_type: data?.containerType || '',
    },
  });
};

/*
 * When the user scrolls to 25% of the page and 30s have passed
 */
const recipePageTimeScrollPercentage = () => {
  sendGTMEvent({
    event: 'timer_recipe_30s_25p',
  });
};

/*
 * When the user spends 5 minutes on the page
 */
const recipePageTime = () => {
  sendGTMEvent({
    event: 'timer_recipe_5min',
  });
};

/*
 * When the user scrolls to a certain percentage of the page
 * @param percentage percentage of the page scrolled
 */
const recipePageScroll = (percentage: number) => {
  sendGTMEvent({
    event: 'scroll_recipe',
    recipe_scroll_percentage: `${percentage}%`,
  });
};

const recipeDetailPageConversionAfterSearch = (
  data: Record<string, string>[],
  userToken: string,
) => {
  const [clickedObjectIDsAfterSearch] = TrackingService.getEventsBetweenTwoLatestPageViews(
    data || [],
    (eventsBetween) => eventsBetween.find((item) => item?.event === 'clickedObjectIDsAfterSearch'),
  );

  if (clickedObjectIDsAfterSearch) {
    const lastClickedObject = clickedObjectIDsAfterSearch?.eventValues as unknown as {
      indexName: string;
      objectIDs: string[];
      queryID: string;
    };

    if (lastClickedObject) {
      TrackingService.convertedObjectIDsAfterSearch({
        indexName: lastClickedObject?.indexName,
        objectIDs: lastClickedObject?.objectIDs as unknown as string[],
        queryID: lastClickedObject?.queryID,
        userToken,
        eventName: 'recipe_converted_after_search',
      });
    }
  }
};

const filtersAppliedRecipeCategoryPage = ({
  userToken,
  indexName,
  filters,
}: {
  userToken: string;
  indexName: string;
  filters: string[];
}) => {
  TrackingService.converted_filters({
    userToken,
    eventName: 'filters_applied_recipe_category_page',
    indexName,
    filters,
  });
};

const recipeCategoryPageConversionAfterFiltering = (
  data: Record<string, string>[],
  objectID: string,
  queryID: string,
  userToken: string,
) => {
  const convertedFilter = data?.find((item) => item?.event === 'converted_filters');

  if (convertedFilter) {
    TrackingService.convertedObjectIDsAfterSearch({
      indexName: (
        convertedFilter?.eventValues as unknown as {
          index: string;
        }
      )?.index,
      objectIDs: [objectID],
      queryID,
      userToken,
      eventName: 'recipe_converted_after_filter',
    });
  }
};

export const RecipeTracking = {
  clickTeaserRecipeList,
  clickPrintRecipe,
  clickRatingRecipe,
  clickSeeCategoryRecipe,
  clickSeeSubcategoryRecipe,
  clickTeaserSeeRecipe,
  recipePage,
  recipeStep,
  recipePreparation,
  clickProductLinkRecipe,
  seeRecipeClick,
  subcategoryComponentRecipeClick,
  recipePageTimeScrollPercentage,
  recipePageTime,
  recipePageScroll,
  recipeDetailPageConversionAfterSearch,
  filtersAppliedRecipeCategoryPage,
  recipeCategoryPageConversionAfterFiltering,
};
