export function getEventsBetweenTwoLatestPageViews<T extends Record<string, string>>(
  dataLayer: T[],
  cb: (argument: T[]) => T | undefined,
  eventName = 'pageview',
): T[] {
  const reversedDataLayer = [...dataLayer].reverse();

  let secondPageviewIndex = -1;
  let firstPageviewIndex = -1;

  for (let i = 0; i < reversedDataLayer.length; i++) {
    const event = reversedDataLayer[i];
    if (event.event === eventName) {
      if (secondPageviewIndex === -1) {
        secondPageviewIndex = i;
      } else {
        firstPageviewIndex = i;
        break;
      }
    }
  }

  if (firstPageviewIndex === -1 || secondPageviewIndex === -1) {
    return []; // No valid pageview events found
  }

  const eventsBetweenPageviews = reversedDataLayer.slice(
    secondPageviewIndex,
    firstPageviewIndex + 1,
  );

  const eventWithClickedObjects = cb(eventsBetweenPageviews);
  return eventWithClickedObjects ? [eventWithClickedObjects] : [];
}

export const HelperMethods = {
  getEventsBetweenTwoLatestPageViews,
};
